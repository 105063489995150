$( document ).ready(function() {
    $(".button-go").on("click", function () {
            $("html, body").animate({
                scrollTop: $("#fridge-middle").offset().top
            }, 1000);
            return false;
    });

    ;(function($, win) {
        $.fn.inViewport = function(cb) {
            return this.each(function(i,el){
                function visPx(){
                    var H = $(this).height(),
                        r = el.getBoundingClientRect(), t=r.top, b=r.bottom;
                    return cb.call(el, Math.max(0, t>0? H-t : (b<H?b:H)));  
                } visPx();
                $(win).on("resize scroll", visPx);
            });
        };
    }(jQuery, window));

    $(".shelf-bowls .shelf1").inViewport(function(px){
        if(px) $(".bowl.b1, .shadow.s1").addClass("go");
        if(px) $(".bowl.b2, .shadow.s2").addClass("go");
        if(px) $(".bowl.b3, .shadow.s3").addClass("go");
        if(px) $(".bowl.b4, .shadow.s4").addClass("go");
        if(px) $(".bowl.b5, .shadow.s5").addClass("go");
        if(px) $(".bowl.b6, .shadow.s6").addClass("go");
        if(px) $(".bowl.b7, .shadow.s7").addClass("go");
    });

    $("#table-base").inViewport(function(px){
        if(px) $(".element.carbs").addClass("go");
        if(px) $(".element.proteins").addClass("go");
        if(px) $(".element.fruits").addClass("go");
        if(px) $(".element.veggies").addClass("go");
        if(px) $(".element.drinks").addClass("go");
        if(px) $(".nutrient .shadow").addClass("go");
    });

    $("#weight-loss .shelf1").inViewport(function(px){
        if(px) $(".arrow").addClass("go");
    });

});